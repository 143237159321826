export default function langSelector(lang) {
    if (lang.toLowerCase().includes("fr")) {
        return "fr";
    }
    else if (lang.toLowerCase().includes("en") ||
        lang.toLowerCase().includes("us")) {
        return "en";
    }
    else {
        return "en";
    }
}
