<script>
import Faq from "./Faq.svelte";

const pages = [{
    id: "136",
    title:"première"
}]
</script>

<div class="container">
    {#each pages as {id,title}}
    <div class="article" key={id}>
        <div class="title">{title}</div>
        <Faq id={id} />
    </div>
    {/each}
    
</div>

<style>

    .title {
        text-align: center;
        font-size: 1.2em;
        color: var(--color-princ);
    
    }
    .container {
        display: flex;
        flex-direction: column;
        gap: 15px;
    }
</style>