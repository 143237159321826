<script lang="ts">
  import { setContext } from "svelte";
  import SupportButton from "./SupportButton.svelte";
  import { ID_SUPPORT_DESC, LANG } from "./utils/constants";
  import langSelector from "./utils/langSelector";
  import { AppStore } from "./utils/store";
  import * as langs from "./utils/lang.json";

  export let showButton: boolean;
  const lang = langs[langSelector(navigator.language)];
  setContext(LANG, lang);

  const links = [
    {
      title: "Campagne",
      page: "home",
    },
    {
      title: "Commentaires",
      page: "comments",
    },
    {
      title: "faq",
      page: "faq",
    },
  ];
</script>

<nav class="nav">
  <div class="container">
    {#each links as { title, page }}
      <div
        key={page}
        class="tab"
        data-current={$AppStore.page === page}
        on:click={() => AppStore.setPage(page)}
      >
        {langs[$AppStore.lang][title]}
      </div>
    {/each}
  </div>
  {#if showButton}
    <SupportButton id={ID_SUPPORT_DESC} />
  {/if}
</nav>

<style>
  .nav {
    position: sticky;
    top: -2px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    gap: 15px;
    width: 100%;
    box-shadow: 0 -1px 0 #ccc, 0 1px 0 #ccc;
    padding: 20px;
    background: rgb(251, 250, 250);
    z-index: 0;
  }

  .container {
    display: flex;
    gap: 10px;
    max-width: 600px;
  }

  .tab {
    cursor: pointer;
  }

  .tab[data-current="true"] {
    opacity: 0.5;
  }
</style>
