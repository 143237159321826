<script lang="ts">
  export let current: number, target: number

  let isOpen = false
</script>

<div class="container" on:click={() => (isOpen = !isOpen)} id="progress">
  <div class="percent" data-visible={isOpen}>
    {((current / target) * 100).toFixed(2)} %
  </div>
  <div
    class="progress"
    style={`width:${((current / target) * 100).toFixed(0)}% !important`}
    data-finish={current >= target}
  />
</div>

<style>
  .container {
    position: relative;
    height: 20px;
    border-radius: 10px;
    background: #bcdbfa;
    margin-bottom: 10px;
    overflow: hidden;
    cursor: pointer;
  }

  .progress {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    background: var(--color-princ);
    transition: 300ms ease-in-out;
  }

  .progress[data-finish='true'] {
    background: #4caf50;
  }

  .percent {
    position: absolute;
    right: 0;
    left: 0;
    text-align: center;
    font-weight: bold;
    color: rgb(49, 49, 49);
    transition: 200ms ease-in-out;
    opacity: 0;
  }

  .percent[data-visible='true'] {
    opacity: 1;
  }
</style>
