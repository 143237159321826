<script lang="ts">
  import IntersectionObserver from "svelte-intersection-observer";
  import { createEventDispatcher } from "svelte";
  import SupportButton from "./SupportButton.svelte";
  import { ID_SUPPORT_DESC } from "./utils/constants";
  import * as langs from "./utils/lang.json";
  import Counter from "./Counter.svelte";
  import { AppStore } from "./utils/store";
import MemeStar from "./MemeStar.svelte";
  const dispatch = createEventDispatcher();
  let element: HTMLElement, intersecting: boolean, money=0;

  function resize() {
    const body: HTMLElement = document.querySelector("body"),
      container: HTMLElement = document.querySelector("#containerVideo"),
      video: HTMLElement = document.querySelector(".videoIframe");

    if (container && video && body) {
      const width =
          body.clientWidth <= 950
            ? body.clientWidth - 40
            : ((container.clientWidth * 66) / 100) - 20,
        height = width * (9 / 16);

      video.style.width = `${width.toFixed(0)}px`;
      video.style.height = `${height.toFixed(0)}px`;
    }
  }

  function handleStar(){
    AppStore.setStar($AppStore.lang)
  }

</script>

<svelte:window on:resize={resize} on:load={()=>setTimeout(resize,500)} />
<header class="hidden">
  {dispatch("show", intersecting ? false : true)}
</header>
<div class="container" id="containerVideo">
  {#if $AppStore.star.toString() ==="fr,en"}
    <MemeStar/>
  {/if}
  <div class="desc">
    <h1>⌨️ {langs[$AppStore.lang]["title"]}</h1>
    <p on:click={handleStar}>{langs[$AppStore.lang]["subtitle"]}</p>
  </div>
  <div class="video" on:click={()=>money++} title={`${20-money} times`}>
    {#if money >= 20}
    <!-- svelte-ignore a11y-missing-attribute -->
    <iframe id="ytplayer" type="text/html" width="720" height="405" allow="autoplay"
    src="https://www.youtube.com/embed/ETxmCCsMoD0?start=45&autoplay=1"
    frameborder="0"></iframe>
    {:else}
        <!-- svelte-ignore a11y-media-has-caption -->
        <video
        muted
        autoplay
        volume={0}
        loop
        class="videoIframe"
        width="900"
        
      >
      <source src="https://micral.mo5.fr/wp-content/uploads/2021/06/loop.webm" type="video/webm">
      <source src="https://micral.mo5.fr/wp-content/uploads/2021/06/Micral_boucle_60min_v1.mp4" type="video/mp4">
      </video>

    {/if}
  </div>
  <div class="goal">
    <Counter />
    <IntersectionObserver {element} bind:intersecting>
      <div bind:this={element} class="support">
        <SupportButton id={ID_SUPPORT_DESC} size="big" />
      </div>
    </IntersectionObserver>
  </div>
</div>

<style>
  .container {
    display: grid;
    grid-template-columns: 2fr 1fr;
    gap: 40px;
    max-width: 1200px;
    margin: 0;
    border-bottom: 1px solid #e7e7e7;
    padding-bottom: 20px;
    box-sizing: border-box;
  }

  .desc {
    grid-column: 1/3;
    text-align: center;
    font-size: 1.2rem;

  }

  h1 {
    text-align: center;
    font-weight: bold;
    font-size: 1.5rem;
    color: var(--color-princ);
    margin: 5px 0;
  }

  .hidden {
    display: none;
  }

  .video {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: cell;
  }

  .videoIframe {
    transition: 200ms ease-in-out;
  }

  .goal {
    display: flex;
    flex-direction: column;
    gap: 25px;
  }

  .support {
    align-self: center;
  }

  @media screen and (max-width: 950px) {
    .container {
      width: 100%;
      padding: 20px;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .goal {
      display: flex;
      flex-direction: column;
      text-align: center;
    }
  }
</style>
