<script lang="ts">
  import { fade } from "svelte/transition";
  import PaymentModale from "./PaymentModale.svelte";
  import { AppStore } from "./utils/store";
  import * as langs from "./utils/lang.json";

  export let id: string, size: string;
  let visible: boolean = false;
</script>

<button class="button" {id} on:click={() => (visible = true)} data-size={size}
  >{langs[$AppStore.lang].participate}
</button>
{#if visible}
  <span class="noDom" transition:fade>
    <PaymentModale on:close={() => (visible = false)} />
  </span>
{/if}

<style>
  .button {
    border: none;
    padding: 10px;
    background: var(--color-princ);
    color: white;
    border-radius: 5px;
    transition: 200ms ease-in-out;
    cursor: pointer;
    animation: bump 5s ease-in-out infinite;
  }

  .button[data-size="big"] {
    width: 100%;
    font-size: 2em;
  }

  .button[data-size="big-fixed"] {
    font-size: 2em;
    position: fixed;
    right: 15px;
    bottom: 20px;
  }
  .noDom {
    position: absolute;
  }

  @keyframes bump {
    0% {
      transform: translate(-1px, -2px) translateZ(0);
    }
    2% {
      transform: translate(1px, 2px) translateZ(0);
    }

    4% {
      transform: translate(-1px, -2px) translateZ(0);
    }
    6% {
      transform: translate(1px, 2px) translateZ(0);
    }

    10% {
      transform: translate(0, 0) translateZ(0);
    }
  }
</style>
