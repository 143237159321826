<script lang="ts">
  import { createEventDispatcher } from "svelte";
  import Cross from "./Cross.svelte";
  import Home from "./Home.svelte";
  import { AppStore } from "./utils/store";
  const dispatch = createEventDispatcher();
</script>

<div class="container">
  <div class="modale">
    <div class="close">
      <Cross on:open={() => dispatch("close")} />
    </div>
    <div class="page">
      <Home id={$AppStore.lang === "fr" ? "192" : "194"}/>
    </div>
  </div>
</div>

<style>
  .container {
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 100;
  }
  .modale {
    display: grid;
    grid-template-rows: auto 1fr;
    align-items: center;
    padding: 15px;
    border-radius: 5px;
    border: 1px solid rgb(230, 230, 230);
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5);
    background: white;
    margin: 20px;
    gap: 15px;
    color: black;
    max-height: 80vh;
  }

  .page {
    position: relative;
    overflow-y: auto;
    width: 100%;
    height: 100%;
    padding: 10px;
  }


  .close {
    justify-self: end;
  }
</style>
