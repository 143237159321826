<script lang="ts">
  import { onMount } from 'svelte'
  import { SvelteToast } from '@zerodevx/svelte-toast'
  import { ApolloClient, InMemoryCache } from '@apollo/client'
  import { setClient } from 'svelte-apollo'
  import Home from './Home.svelte'
  import Header from './Header.svelte'
  import Description from './Description.svelte'
  import Nav from './Nav.svelte'
  import langSelector from './utils/langSelector'
  import SplashScreen from './SplashScreen.svelte'
  import Aside from './Aside.svelte'
  import Commentaries from './Commentaries.svelte'
  import FaqPage from './FaqPage.svelte'
  import { AppStore } from './utils/store'
  import ScrollToTop from './ScrollToTop.svelte'
  import Footer from './Footer.svelte'
  import Share from './Share.svelte'
  import Thanks from './Thanks.svelte'
  import Pusher from 'pusher-js'

  onMount(() => {
    //TODO remove to env
    const pusher = new Pusher('f86450919ec0605e706c', {
      cluster: 'eu',
    })

    const channel = pusher.subscribe('micral')
    channel.bind('paypal', (msg) => {
      if (msg.type && msg.type === 'counter') {
        const { amount, count } = msg
        AppStore.setCounters({ amount, count, currentTarget: amount })
      }
      if (msg.type && msg.type === 'com') {
        if (msg.commentaries) {
          AppStore.setComments(msg.commentaries)
        }
      }
    })
  })

  onMount(() => {
    const langSelect = langSelector(navigator.language)
    AppStore.setLang(langSelect)
  })

  let show: boolean,
    isMobile: boolean = false

  const client = new ApolloClient({
    uri: process.env.WORKER_URL,
    cache: new InMemoryCache(),
  })

  setClient(client)

  function handleMessage(event) {
    show = event.detail
  }

  function handleSize() {
    const body: HTMLElement = document.querySelector('body')
    isMobile = body.clientWidth <= 950

    if (!isMobile) {
      AppStore.setPage('home')
    }
  }
</script>

<svelte:head>
  <title>MO5.COM : Restauration du Micral N</title>>
</svelte:head>

<svelte:window on:resize={handleSize} on:load={handleSize} />

{#if $AppStore.lang && $AppStore.page !== 'thanks'}
  <div class="page">
    <Share position="fixed" />
    <div class="header">
      <Header bind:show bind:isMobile />
    </div>
    <SplashScreen />
    <div class="container" id="scroll">
      <Description on:show={handleMessage} />
      {#if show}
        <ScrollToTop />
      {/if}
      {#if isMobile}
        <Nav showButton={show} />
      {/if}
      <main class="main">
        {#if $AppStore.page === 'comments'}
          <div class="text">
            <Commentaries />
          </div>
        {:else if $AppStore.page === 'faq'}
          <div class="text">
            <FaqPage />
          </div>
        {:else if $AppStore.page === 'home'}
          <div class="text">
            {#if $AppStore.lang === 'fr'}
              <Home id="13" />
            {:else}
              <Home id="152" />
            {/if}
            <Home id="224" />
          </div>
        {/if}
        {#if !isMobile}
          <aside class="aside">
            <Aside />
          </aside>
        {/if}
      </main>
      <footer class="footer">
        <Footer />
      </footer>
    </div>
  </div>
{:else}
  <div class="page">
    <div class="header">
      <Header bind:show bind:isMobile />
    </div>
    <div class="container" id="scroll">
      <div class="text">
        <Thanks />
      </div>
      <footer class="footer">
        <Footer />
      </footer>
    </div>
  </div>
{/if}

<SvelteToast />

<style>
  .page {
    display: grid;
    grid-template-rows: auto 1fr;
    height: 100vh;
    gap: 20px;
  }
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    padding: 0 10px;
    overflow-y: auto;
  }
  .main {
    max-width: 1200px;
    line-height: 1.6em;
    display: grid;
    grid-template-columns: 2fr 1fr;
    gap: 40px;
    padding: 20px 0 100px;
    box-sizing: border-box;
  }

  .footer {
    width: 100%;
  }

  @media screen and (max-width: 1250px) {
    .main {
      padding: 15px;
    }
  }

  @media screen and (max-width: 950px) {
    .main {
      display: flex;
      flex-direction: column;
      width: 100%;
    }

    .page {
      gap: 0;
    }

    .container {
      margin: 0;
      padding: 0;
    }
  }
</style>
