<script lang="ts">
  import LegalNotice from "./LegalNotice.svelte";
  import SocialNetwork from "./SocialNetwork.svelte";
  import * as langs from "./utils/lang.json";
  import { AppStore } from "./utils/store";

  let isOpenLegal = false;
</script>

<div class="container">
  <div class="desc">
    <a class="logo" target="_blank" href="https://mo5.com">
      <img
      src="/logo-mo5.png"
        alt="mo5.com"
      />
    </a>
    <div class="desc">
      {langs[$AppStore.lang].aboutMo5}
    </div>
    <div class="link" on:click={() => (isOpenLegal = true)}>
      {langs[$AppStore.lang].legalMention}
    </div>
  </div>
  <div class="rs">
    <SocialNetwork isColumn={true} />
  </div>
  {#if isOpenLegal}
    <LegalNotice on:close={() => (isOpenLegal = false)} />
  {/if}
</div>

<style>
  .container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    width: 100%;
    padding: 20px;
    background: var(--color-princ);
    color: white;
  }

  .desc,
  .rs {
    display: flex;
    flex-direction: column;
  }

  .desc {
    font-size: 0.9em;
  }

  .rs {
    align-items: center;
  }

  .logo {
    display: block;
    max-width: 200px;
  }

  .link {
    text-decoration: underline;
    cursor: pointer;
  }

  @media screen and (max-width: 500px){
    .container ,.desc{
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
</style>
