<script lang="ts">
  import { onMount } from "svelte";
  import dayjs from "dayjs";
  import marked from "marked";
  import { gql } from "@apollo/client";
  import { getClient } from "svelte-apollo";
  import fr from "dayjs/locale/fr";
  import en from "dayjs/locale/en";
  import langSelector from "./utils/langSelector";
  import localizedFormat from "dayjs/plugin/localizedFormat";
  import relativeTime from "dayjs/plugin/relativeTime";
  import { AppStore } from "./utils/store";
  dayjs.extend(localizedFormat);
  dayjs.extend(relativeTime);
  dayjs.locale(langSelector(navigator.language) === "fr" ? fr : en);
  let plus = false;

  const client = getClient();

  function handleFetch() {
    client
      .query({
        query: gql`
          {
            commentaries {
              name
              message
              date
            }
          }
        `,
      })
      .then((response) => {
        AppStore.setComments(response.data.commentaries)
      });
  }

  onMount(() => {
    if($AppStore.comments.length === 0){
      handleFetch();
    }
  });

  $: sortComs = $AppStore.comments.sort((a, b) => {
    const dateA = new Date(a.date),
      dateB = new Date(b.date);
    return dateB.getTime() - dateA.getTime();
  });
</script>

<section class="container">
  {#each plus ? sortComs : sortComs.slice(0, 3) as { name, message, date }}
    <article key={name + date} class="article">
      <div class="name">
        {name}
      </div>
      <div class="date">{dayjs(date).fromNow()}</div>
      <div class="message">
        {@html marked(message)}
      </div>
    </article>
  {/each}
  {#if !plus && sortComs.length > 3}
    <div class="plus" on:click={() => (plus = true)}>
      <i class="fad fa-plus-circle" />
    </div>
  {/if}
</section>

<style>
  .container {
    display: flex;
    flex-direction: column;
    padding: 10px;
    font-size: 0.9rem;
    gap: 10px;
  }

  .article:nth-child(n) {
    border-bottom: 1px solid rgb(195, 195, 195);
  }

  .article:last-child {
    border-bottom: none;
  }

  .date {
    font-size: 0.7em;
    font-style: italic;
    color: rgb(110, 110, 110);
  }

  .name {
    font-size: 1.1em;
    font-weight: 600;
    color: var(--color-princ);
  }

  .message {
    line-height: 1.2em;
  }

  .article {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }

  .plus {
    color: var(--color-princ);
    text-align: center;
    font-size: 2em;
    cursor: pointer;
    transition: 200ms ease-in-out;
  }
</style>
