<script lang="ts">
import ChoiceLang from "./ChoiceLang.svelte";
import SocialNetwork from "./SocialNetwork.svelte";
import SupportButton from "./SupportButton.svelte";
import { ID_SUPPORT_DESC } from "./utils/constants";

export let show: boolean, isMobile:boolean
</script>

<header class="header">
  <div class="lang">
    {#if !isMobile}
    <ChoiceLang/>
    {/if}
  </div>
  <div class="flex">
    <div class="logo">
      <img src="/logo-mo5.png" alt="logo" on:click={()=>window.location.reload()}/>
    </div>
  </div>

  <div class="flex">
    {#if !isMobile}
      {#if show}
      <SupportButton id={ID_SUPPORT_DESC} size="normal"/>
      {:else}
      <SocialNetwork/>
      {/if}
    {/if}

  </div>
</header>

<style>
  .header {
    width: 100%;
    display: grid;
    align-items: center;
    justify-content: center;
    align-content: center;
    grid-template-columns: 1fr 2fr 1fr;
    text-align: center;
    padding: 5px;
    gap: 10px;
    border-bottom: 3px solid rgb(202, 202, 202);
    box-shadow: 0 5px 10px rgba(0,0,0,.2);
  }

  .flex {
    display: flex;
    flex-direction: column;
    height: 100%;
    align-items: center;
    justify-content: center;
    align-content: center;
  }
  .logo {
    max-width: 200px;
    cursor: pointer;
  }

  @media screen and (max-width: 500px) {

    .logo {
      max-width: 200px !important;
    }

    .header {
      display: flex;
      flex-direction: column-reverse;
      gap:0;
    }

    .logo {
      max-width: 500px;
      margin: 0 25px;
    }
  }
</style>
