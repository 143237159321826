<script lang="ts">
  import { fly } from "svelte/transition";
  export let title: string;
  export let openDefault: boolean;
  let isOpen: boolean = openDefault === false ? false : true;
</script>

<div class="container">
  <div class="title" on:click={() => (isOpen = !isOpen)} data-open={!!isOpen}>
    {title} <i class="fas fa-chevron-up icon" data-open={!!isOpen} />
  </div>

  {#if isOpen}
    <div class="article" transition:fly={{ opacity: 0, y: -20, duration: 300 }}>
      <slot />
    </div>
  {/if}
</div>

<style>
  .container {
    margin: 0;
  }

  .title {
    cursor: pointer;
    font-size: 1em;
    padding-bottom: 10px;
    margin-bottom: 10px;
    transition: 200ms ease-in-out;
    padding: 10px;
  }

  .title[data-open="true"] {
    border-bottom: 1px solid rgb(195, 195, 195);
    background: #bcdbfa;
    color: var(--color-princ);
  }

  .title,
  .icon {
    transition: 200ms ease-in-out;
    cursor: pointer;
  }

  .icon[data-open="false"] {
    color: rgb(97, 97, 97);
  }

  .icon[data-open="true"] {
    transform: rotate(0.5turn);
    color: var(--color-princ);
  }

  .article {
    overflow: hidden;
  }
</style>
