<script lang="ts">
  import Counter from "./Counter.svelte";
  import Editor from "./Editor.svelte";
  import Share from "./Share.svelte";
  import * as langs from "./utils/lang.json";
  import { AppStore } from "./utils/store";
  import { getClient } from "svelte-apollo";
  import { gql } from "@apollo/client";
  import { toastDanger } from "./utils/toastDanger";
  import { toast } from "@zerodevx/svelte-toast";
  import { onMount } from "svelte";

  let name = "",
    message = "";

  onMount(() => {
    const { given_name, surname } = $AppStore;
    if(given_name && surname){
      name = given_name + " " + surname.slice(0, 1) + ".";
    }

  });

  const client = getClient(),
    addComm = gql`
      mutation addComm(
        $name: String!
        $email: String!
        $invoiceId: String!
        $message: String!
      ) {
        addComm(
          name: $name
          email: $email
          invoiceId: $invoiceId
          message: $message
        ) {
          name
          message
        }
      }
    `;

  function setMessage(e: { detail: string }) {
    message = e.detail;
  }

  async function handleSubmitForm(e: { preventDefault: () => void }) {
    e.preventDefault();

    if(name.length< 1 || message.length <1){
      toast.push(
        $AppStore.lang === "fr" ? "des champs sont vides": "fields as empty",
          toastDanger
        );
    } else {
      const variables = {
      name,
      message,
      invoiceId: $AppStore.invoiceId,
      email: $AppStore.email,
    };
    client
      .mutate({ mutation: addComm, variables })
      .then(({ data }) => {
        toast.push(langs[$AppStore.lang || "fr"].comPublish);
        AppStore.setPage("home");
      })
      .catch((e) => {
        toast.push(
          langs[$AppStore.lang || "fr"][e.message || "error"],
          toastDanger
        );
      });
    }

  }
</script>

<svelte:head>
  <title>{langs[$AppStore.lang || "fr"].thanks} | Micral</title>>
</svelte:head>

<div class="container">
  <div class="page">
    <div class="title">
      <h1>{langs[$AppStore.lang || "fr"].thanksTitle}</h1>
    </div>
    <section class="section">
      <Counter />
    </section>

    <section class="section">
      <h2>{langs[$AppStore.lang || "fr"].helpRS}</h2>
      <Share position="line" />
    </section>
    <section class="section">
      <h2>{langs[$AppStore.lang || "fr"].goldBook}</h2>
      <div class="article">
        <form class="form" on:submit={handleSubmitForm}>
          <div class="title">{langs[$AppStore.lang || "fr"].writeCom}</div>
          <fieldset>
            <label for="name">nom</label>
            <input type="text" bind:value={name} required/>
          </fieldset>
          <Editor on:write={setMessage} />
          <button class="button">{langs[$AppStore.lang || "fr"].send}</button>
        </form>
      </div>

    </section>
  </div>
</div>

<style>
  .container {
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 15px;
    box-sizing: border-box;
    align-items: center;
    gap: 15px;
  }

  .page {
    max-width: 970px;
    min-width: 500px;
    display: flex;
    flex-direction: column;
    gap: 15px;
  }

  h2 {
    font-size: 1.2em;
    font-weight: 600;
  }

  .section {
    padding: 20px;
    border: 1px solid rgb(235, 235, 235);
  }

  .title {
    text-align: center;
  }

  .form {
    display: flex;
    min-width: 500px;
    flex-direction: column;
    gap: 15px;
  }

  .button {
    margin: 0 15px;
  }

  .article {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  @media screen and (max-width: 500px){
    .form {
    min-width: auto;
    width: 100%;
  }

  .page {
    min-width: auto;
  }
  }
</style>
