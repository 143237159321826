<script lang="ts">
  import { createEventDispatcher, onMount } from 'svelte'
  import Checkout from './Checkout.svelte'
  import * as langs from './utils/lang.json'
  import Cross from './Cross.svelte'
  import { AppStore } from './utils/store'
  import { getClient } from 'svelte-apollo'
  import gql from 'graphql-tag'

  const dispatch = createEventDispatcher(),
    client = getClient(),
    fastActions = [10, 20, 50, 100, 500, 1000]

  let value = 15,
    paypalLogin: string,
    comForm = false,
    becomeMember = true,
    values: Array<number> = [value]

  function paypalState(e) {
    AppStore.setThanks(e.detail)
  }

  function close() {
    comForm = true
    AppStore.setIsLoading(false)
    dispatch('close', true)
  }

  function handleChange(val: number) {
    value = val
    values = [val]
  }

  function paypalToken() {
    client
      .query({
        query: gql`
          {
            paypalLogin
          }
        `,
      })
      .then(({ data }) => (paypalLogin = data.paypalLogin))
  }

  onMount(() => {
    paypalToken()
  })
</script>

<div class="container">
  <div class="modale">
    <div class="close">
      <Cross on:open={close} />
    </div>
    {#if $AppStore.isLoading}
      <div class="paypalWait">{langs[$AppStore.lang || 'fr'].waitPaypal}</div>
    {:else}
      <div class="actions">
        {#each fastActions as fastAction}
          <div
            class="action"
            data-current={values[0] === fastAction}
            key={fastAction}
            on:click={() => handleChange(fastAction)}
          >
            {fastAction} €
          </div>
        {/each}
      </div>
      <div class="sum">
        <div class="your">
          {langs[$AppStore.lang || 'fr'].yourParticipation}
        </div>
        <div class="valueInput">
          <input
            type="number"
            value={values[0]}
            on:change={(e) => handleChange(e.target.value)}
            class="input"
          />
        </div>
        <div class="sign">€</div>
      </div>
    {/if}
    {#if values[0] >= 15 || value >= 15}
      <div class="become">
        <div class="becomeTitle">{langs[$AppStore.lang].yourContrib} :</div>
        <div
          class="check"
          on:click={() => {
            becomeMember = !becomeMember
            console.log('become member :', becomeMember)
          }}
        >
          <div class="checkbox" data-ok={becomeMember} />
          <div class="text">{langs[$AppStore.lang].becomeMember}</div>
        </div>
      </div>
    {/if}
    <div class="buttons">
      {#if paypalLogin}
        <Checkout
          bind:value={values[0]}
          {becomeMember}
          bind:paypalLogin
          on:paypalState={paypalState}
        />
      {:else}
        ...
      {/if}
    </div>
  </div>
</div>

<style>
  .container {
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 100;
  }
  .modale {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 15px;
    border-radius: 5px;
    border: 1px solid rgb(230, 230, 230);
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5);
    background: white;
    margin: 20px;
    gap: 15px;
    max-height: 80vh;
    overflow-y: auto;
  }

  .sum {
    display: inline-grid;
    grid-template-columns: 1fr auto auto;
    font-size: 1.5rem;
    justify-content: center;
    align-items: center;
    gap: 5px;
    align-content: center;
  }
  .range {
    width: 100%;
  }

  .input {
    color: var(--color-princ);
    text-align: right;
    border: none;
    font-size: 1.5rem;
    padding: 0;
    outline: none;
    font-weight: 600;
    max-width: 150px;
  }

  .actions {
    display: inline-grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 15px;
    padding: 15px 0;
  }

  .action {
    border: 1px solid rgb(218, 218, 218);
    padding: 5px;
    font-weight: 500;
    font-size: 2rem;
    transition: 200ms ease-in-out;
    cursor: pointer;
    text-align: center;
  }

  .action[data-current='true'] {
    border: 1px solid var(--color-princ);
    color: var(--color-princ);
  }
  .action:hover {
    transform: translate(-1px, -1px);
    box-shadow: 5px 5px 0 rgba(0, 0, 0, 0.2);
  }
  .close {
    width: 100%;
    display: flex;
    position: sticky;
    top: 5px;
    background: white;
    flex-direction: row-reverse;
  }

  .buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap: 10px;
  }

  .sign {
    padding-top: -1px;
    border-right: 1px solid #ececec;
    line-height: 1.8rem;
    padding-right: 3px;
  }

  .input {
    border-left: 1px solid #ececec;
  }
  .input,
  .sign {
    border-top: 1px solid #ececec;
    border-bottom: 1px solid #ececec;
  }

  .paypalWait {
    font-style: italic;
    text-align: center;
    color: var(--color-princ);
  }

  .checkbox {
    border: 1px solid rgba(0, 0, 0, 0.3);
    width: 20px;
    height: 20px;
    border-radius: 3px;
    position: relative;
    transition: 200ms ease-in-out;
  }

  .checkbox:before {
    position: absolute;
    content: '';
    border-radius: 3px;
    background: var(--color-princ);
    transition: 200ms ease-in-out;
    opacity: 1;
  }

  .checkbox[data-ok='true']:before {
    top: 2px;
    bottom: 2px;
    right: 2px;
    left: 2px;
  }

  .checkbox[data-ok='false']:before {
    top: 50%;
    bottom: 50%;
    right: 2px;
    left: 2px;
    opacity: 0;
  }

  .check {
    display: inline-grid;
    grid-template-columns: auto 1fr;
    gap: 10px;
    padding: 10px 0;
    cursor: pointer;
  }
  .become {
    border: 1px dashed rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    padding: 10px;
    margin: 20px;
  }
  .becomeTitle {
    font-style: italic;
  }

  @media screen and (max-width: 400px) {
    .sum {
      font-size: 1.2rem;
    }
  }
</style>
