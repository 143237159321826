<script lang="ts">
  export let isColumn = false;

  const networks = [
    {
      name: "Site MO5",
      icon: "fad fa-globe-europe",
      color: "#4088cf",
      link: "https://mo5.com/site/",
    },
    {
      name: "Facebook",
      color: "#2d88ff",
      icon: "fab fa-facebook",
      link: "https://www.facebook.com/AssoMO5/",
    },
    {
      name: "Twitter",
      color: "#1da1f2",
      icon: "fab fa-twitter",
      link: "https://twitter.com/assomo5",
    },
    {
      name: "Youtube",
      color: "#f00",
      icon: "fab fa-youtube",
      link: "https://www.youtube.com/assomo5",
    },
    {
      name: "Discord",
      color: "#5865f2",
      icon: "fab fa-discord",
      link: "https://discord.com/invite/phG9zNk",
    },
    {
      name: "Instagram",
      color: "#f04d4f",
      icon: "fab fa-instagram",
      link: "https://www.instagram.com/assomo5/",
    },
    {
      name: "Twitch",
      color: "#9147ff",
      icon: "fab fa-twitch",
      link: "https://www.twitch.tv/assomo5",
    },
  ];
</script>

<div class="container" data-col={isColumn}>
  {#each networks as { name, icon, link, color }}
    <a class="article" title={name} key={name} href={link} target="_blank">
      <div class="icon" style={`background:${color}`}>
        <i class={icon}/>
      </div>
      {#if isColumn}
        <div class="title">
          {name}
        </div>
      {/if}
    </a>
  {/each}
</div>

<style>
  .container {
    display: flex;
    gap: 5px;
  }

  .container[data-col="true"] {
    display: grid;
    grid-template-columns: repeat(2,1fr);
    gap: 15px;
  }

  .icon {
    padding: 5px;
  }
  .article {
    font-size: 1.2em;
    transition: 200ms ease-in-out;
    color: white;
    display: flex;
    gap: 5px;
    align-content: center;
    align-items: center;
  }

  .title {
      font-size: .8em;
  }

  .fab, .fad {
      transition: 200ms ease-in-out;
  }

  .fab:hover, .fad:hover {
      transform: translate(-2px,-2px) scale(1.05);
      border-radius: 3px;
  }
  .article:hover {
    text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.3);
  }
</style>
