<script lang="ts">
  import { onDestroy, onMount } from 'svelte'
  import App from './App.svelte'
  import * as langs from './utils/lang.json'
  import { AppStore } from './utils/store'
  const LS = 'splashMicral'
  let isVisible = true,
    video: HTMLVideoElement,
    listener,
    ended

  function handleSkip() {
    localStorage.setItem(LS, 'false')
    isVisible = false
  }

  onMount(() => {
    const ls = localStorage.getItem(LS)

    if (ls || window.location.search.includes('splash=false')) {
      isVisible = false
    }

    video = document.querySelector('#splash')
    listener = video.addEventListener('timeupdate', () => {
      if (ended) {
        setTimeout(() => (isVisible = false), 300)
      }
    })
  })

  onDestroy(() => video.removeEventListener('timeupdate', listener))
</script>

{#if isVisible}
  <div class="container">
    <div class="modale">
      <!-- svelte-ignore a11y-media-has-caption -->
      <video muted controls autoplay bind:ended id="splash">
        <source
          src="https:/micral.mo5.fr/wp-content/uploads/2021/06/v6.webm"
          type="video/webm"
        />
        <source
          src="https://micral.mo5.fr/wp-content/uploads/2021/05/trailer-v6.mp4"
          type="video/mp4"
        />
      </video>
      <div class="skip">
        <div class="skipButton" on:click={handleSkip}>
          {langs[$AppStore.lang || 'fr'].dontDisplay}
        </div>
        <div class="skipButton" on:click={() => (isVisible = false)}>
          {langs[$AppStore.lang || 'fr'].skip}
        </div>
      </div>
    </div>
  </div>
{/if}

<style>
  .container {
    position: fixed;
    background: rgba(0, 0, 0, 0.9);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 100;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .modale {
    max-width: 900px;
    padding: 20px;
    position: relative;
  }
  .modale video {
    width: 100%;
    height: auto;
    overflow: hidden;
    border-radius: 10px;
  }

  .skip {
    position: absolute;
    top: calc(100% + 5px);
    right: 25px;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    gap: 10px;
  }
  .skipButton {
    background: black;
    color: white;
    padding: 10px 15px;
    cursor: pointer;
  }
</style>
