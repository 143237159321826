<script lang="ts">
    import { toast } from "@zerodevx/svelte-toast";
    import type { WP_REST_API_Post } from "wp-types";
    import { wp } from "./utils/wp";
    import { toastDanger } from "./utils/toastDanger";
    export let id: string
    const promise: Promise<{ data: WP_REST_API_Post }> = wp.get(id);
  </script>
  
  <svelte:head>
    <title>Micral</title>>
  </svelte:head>
  {#await promise}
    <div class="loader">...</div>
  {:then { data }}
    <div class="container">{@html data.content.rendered}</div>
  {:catch error}
    {toast.push(error.message, toastDanger)}
  {/await}
  
  <style>
    .container {
      font-size: .8em;
      line-height:1.5em;
      padding: 8px
    }
  
    .container {
      text-align: justify;
    }
  
    @media screen and (max-width: 950px) {
      .container {
        padding: 15px;
      }
    }
  </style>  