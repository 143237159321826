<script lang="ts">
  function handleClick() {
    const main = document.querySelector("#scroll");

    const scroll = setInterval(() => {
      main.scrollTop -= 10;
      if (main.scrollTop <= 0) {
        clearInterval(scroll);
      }
    }, 1);
  }
</script>

<div class="container" on:click={handleClick}>
  <button class="button">
    <i class="fad fa-angle-up" />
  </button>
</div>

<style>
  .container {
    position: fixed;
    font-size: 1.3em;
    bottom: 20px;
    padding: 0 10px;
    right: 10px;
  }

  @media screen and (max-width: 400px) {
    .container {
      bottom: 60px;
    }
  }
</style>
