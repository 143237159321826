<script lang="ts">
     import { AppStore } from "./utils/store";
     import Cross from "./Cross.svelte";


</script>

<div class="container">
    <div class="modale">
        <div class="cross">
            <Cross on:open={()=>AppStore.setStar(undefined)} />
        </div>

        <img src="/star.png" alt="meme" />
        <div class="cross"></div>
    </div> 
</div>

<style>
    .container {
        position: fixed;
        z-index: 10;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: rgba(0,0,0,.3);
        gap: 10px;

    }

    .cross {
        display: flex;
        flex-direction: row-reverse;
    }

    .modale {
        background: white;
        display: flex;
        flex-direction: column;
        gap: 10px;
        padding: 15px;
        border-radius: 10px;
    }
</style>