<script>
  import { createEventDispatcher } from "svelte";
  const dispatch = createEventDispatcher();
</script>

<div class="cross-container" on:click={() => dispatch("open", true)}>
  <div class="cross" />
</div>

<style>
  /* cross style */
  .cross {
    position: relative;
    width: 20px;
    height: 20px;
    transform: rotate(45deg);
    cursor: pointer;
    transition: 200ms ease-in-out;
  }

  .cross:after,
  .cross:before {
    content: "";
    position: absolute;
    display: block;
    background: var(--color-princ);
    border-radius: 20px;
    transition: 200ms ease-in-out;
  }

  .cross:before {
    width: 4px;
    height: 100%;
    left: calc(50% - 2px);
  }

  .cross:after {
    width: 100%;
    height: 4px;
    top: calc(50% - 2px);
  }

  .cross:hover {
    transform: rotate(135deg);
  }

  .cross:hover:after,
  .cross:hover:before {
    background: darkred;
    border-radius: 0;
  }
</style>
