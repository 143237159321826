<script lang="ts">
  import { onDestroy, onMount } from 'svelte'
  import ProgressBar from './ProgressBar.svelte'
  import * as langs from './utils/lang.json'
  import { gql } from '@apollo/client'
  import { getClient } from 'svelte-apollo'
  import { AppStore } from './utils/store'
  import JSConfetti from 'js-confetti'

  let interval,
    isInit = true

  const client = getClient(),
    regex: RegExp = /\B(?=(\d{3})+(?!\d))/g,
    target: number = 20000

  function handleFetch() {
    client
      .query({
        query: gql`
          {
            backersCount {
              count
              amount
            }
          }
        `,
      })
      .then((response) => {
        const { amount, count } = response.data.backersCount
        AppStore.setCounters({
          amount,
          count,
          currentTarget: $AppStore.currentTarget,
        })
        if (isInit) {
          handleUpdateCounter()
          isInit = false
        }
      })
  }

  function handleUpdateCounter() {
    interval = setInterval(async () => {
      if ($AppStore.currentTarget > 0) {
        if ($AppStore.amount < 200) {
          AppStore.incrementCounter(1)
        } else if ($AppStore.amount < $AppStore.currentTarget - 300) {
          AppStore.incrementCounter(100)
        } else {
          AppStore.incrementCounter(1)
        }
      }

      if ($AppStore.amount >= $AppStore.currentTarget) {
        clearInterval(interval)

        const jsConfetti = new JSConfetti()
        if ($AppStore.currentTarget >= target) {
          jsConfetti.addConfetti({
            emojis: ['🖥', '✨', '🕹', '⌨️', '🖱', '💾'],
          })
        }
      }
    }, 1)
  }

  onDestroy(() => {
    clearInterval(interval)
  })

  onMount(() => {
    if ($AppStore.count === 0) {
      handleFetch()
    }
  })

  $: euroCounter = `${$AppStore.amount.toString().replace(regex, ' ')} €`
</script>

<div class="container">
  <ProgressBar
    current={$AppStore.amount < 1000 ? 1000 : $AppStore.amount}
    {target}
  />
  <div class="engaged">{euroCounter}</div>
  <div class="descEngaged">
    {langs[$AppStore.lang]['engaged']}
    {target.toString().replace(regex, ' ')} €
  </div>
  <div class="backer">{$AppStore.count}</div>
  <div class="descEngaged">
    {langs[$AppStore.lang]['backer']}{$AppStore.count > 1 ? 's' : ''}
  </div>
</div>

<style>
  .container {
    display: flex;
    flex-direction: column;
    padding: 0;
  }

  .engaged,
  .backer {
    font-size: 3rem;
    font-weight: bold;
  }

  .engaged {
    color: var(--color-princ);
  }

  .backer {
    color: #656969;
  }

  .descEngaged {
    font-size: 0.8rem;
    color: rgb(126, 126, 126);
  }
</style>
