import { writable } from "svelte/store";
export const initialState = {
    isLoading: false,
    page: "home",
    trigger: false,
    star: [],
    count: 0,
    amount: 0,
    currentTarget: 0,
    comments: [],
    lang: "fr",
    invoiceId: null,
    email: null,
    given_name: null,
    surname: null
};
function appStore() {
    const { subscribe, update } = writable(initialState);
    return {
        subscribe,
        setTrigger: (payload) => {
            update((state) => {
                const newState = Object.assign({}, state);
                newState.trigger = payload;
                state = newState;
                return newState;
            });
        },
        setContrib({ count, amount }) {
            update((state) => {
                const newState = Object.assign({}, state);
                newState.trigger = true;
                newState.isLoading = false;
                state = newState;
                return newState;
            });
        },
        setIsLoading(value) {
            update((state) => {
                const newState = Object.assign({}, state);
                newState.isLoading = value;
                state = newState;
                return newState;
            });
        },
        setPage(value) {
            update((state) => {
                const newState = Object.assign({}, state);
                newState.page = value;
                state = newState;
                return newState;
            });
        },
        setLang(value) {
            update((state) => {
                const newState = Object.assign({}, state);
                newState.lang = value;
                state = newState;
                return newState;
            });
        },
        setStar(value) {
            update((state) => {
                const newState = Object.assign({}, state);
                if (value !== undefined) {
                    const index = newState.star.findIndex(it => it === value);
                    if (index === -1) {
                        newState.star.push(value);
                    }
                    else {
                        newState.star.splice(0, 1);
                    }
                }
                else {
                    newState.star = [];
                }
                state = newState;
                return newState;
            });
        },
        incrementCounter(increment) {
            update((state) => {
                const newState = Object.assign({}, state);
                newState.amount += increment;
                return newState;
            });
        },
        setCounters({ amount, count, currentTarget }) {
            update((state) => {
                const newState = Object.assign({}, state);
                newState.amount = amount;
                newState.count = count;
                if (currentTarget) {
                    newState.currentTarget = currentTarget;
                }
                else {
                    newState.currentTarget = amount;
                    newState.amount = 0;
                }
                return newState;
            });
        },
        setComments(comments) {
            update((state) => {
                const newState = Object.assign({}, state);
                newState.comments = comments;
                return newState;
            });
        },
        setThanks({ email, id, given_name, surname, }) {
            update((state) => {
                const newState = Object.assign({}, state);
                newState.isLoading = false;
                newState.page = "thanks";
                newState.email = email;
                newState.given_name = given_name;
                newState.surname = surname;
                newState.invoiceId = id;
                state = newState;
                return newState;
            });
        },
    };
}
export const AppStore = appStore();
