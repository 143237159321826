<script lang="ts">
  import { createEventDispatcher } from "svelte";
  import * as langs from "./utils/lang.json";
  import marked from "marked";
  import { AppStore } from "./utils/store";
  const dispatch = createEventDispatcher();
  let type = "editor",
    text = "";

  function detectKey(e) {
    if (e.key === "Enter") {
      text += `\\`;
    }
  dispatch("write", text);
  }

  $: previewText =
  text.substr(text.length - 1) === "\\"
      ? text.slice(0, text.length - 1)
      : text;

</script>

<div class="container">
    <textarea on:keydown={detectKey} bind:value={text} class="text" on:keyup={ ()=>  dispatch("write", text)} />
</div>

<style>
  .container {
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow-y: auto;
    padding: 0 15px;
  }

  .text {
    width: 100%;
    border: 1px solid #c7c7c7;
    background: white;
    min-height: 150px;
    padding: 8px;
    overflow: hidden;
    position: relative;
  }

</style>
