<script lang="ts">
  import Accordion from "./Accordion.svelte";
  import Commentaries from "./Commentaries.svelte";
  import Faq from "./Faq.svelte";
  import * as langs from "./utils/lang.json";
  import { AppStore } from "./utils/store";
</script>

<div class="container">
  <div class="title">FAQ</div>
  <section class="section">
    <article class="article">
      <Accordion
        title={langs[$AppStore.lang || "fr"].faqAvailablePay}
        openDefault={true}
      >
        {#if $AppStore.lang === "fr"}
          <Faq id="136" />
        {:else}
          <Faq id="200" />
        {/if}
      </Accordion>
    </article>
    <article class="article">
      <Accordion
        title={langs[$AppStore.lang || "fr"].whenDon}
        openDefault={false}
      >
        {#if $AppStore.lang === "fr"}
          <Faq id="204" />
        {:else}
          <Faq id="206" />
        {/if}
      </Accordion>
    </article>
    <article class="article">
      <Accordion
        title={langs[$AppStore.lang || "fr"].dotAccountPaypal}
        openDefault={false}
      >
        {#if $AppStore.lang === "fr"}
          <Faq id="208" />
        {:else}
          <Faq id="210" />
        {/if}
      </Accordion>
    </article>
  </section>
  <section class="section">
    <Accordion title={$AppStore.lang === "fr" ? "Commentaires": "Comments"} openDefault={true}>
      <Commentaries />
    </Accordion>
  </section>
</div>

<style>
  .container {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }

  .section {
    border: 1px solid rgb(195, 195, 195);
    border-radius: 10px;
    overflow: hidden;
  }

  .article:nth-child(n) {
    border-bottom: 1px solid rgb(195, 195, 195);
  }

  .article:last-child {
    border-bottom: none;
  }

  .title {
    font-size: 1.3em;
    font-weight: 600;
  }
</style>
