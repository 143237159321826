<script>
  import { gql } from "@apollo/client";
  import { loadScript } from "@paypal/paypal-js";
  import { getClient } from "svelte-apollo";
  import { toast } from "@zerodevx/svelte-toast";
  import * as langs from "./utils/lang.json";
  import { toastDanger } from "./utils/toastDanger";
  import { createEventDispatcher } from "svelte";
  import { AppStore } from "./utils/store";

  export let value, paypalLogin, becomeMember;
  const dispatch = createEventDispatcher(),
    client = getClient(),
    PAYPAL_API_KEY = process.env.PAYPAL_API_KEY,
    addInvoice = gql`
      mutation addInvoice(
        $invoiceinfo: Invoiceinfo
        $purchaseInput: PurchaseInput
        $addressInput: AddressInput
        $backerInput: BackerInput
        $choice: Boolean
        $lang: String
      ) {
        addInvoice(
          invoiceinfo: $invoiceinfo
          purchaseInput: $purchaseInput
          addressInput: $addressInput
          backerInput: $backerInput
          choice: $choice
          lang: $lang
        ) {
          count
          amount
        }
      }
    `;

  loadScript({
    "client-id": PAYPAL_API_KEY,
    intent: "capture",
    commit: "false",
    vault: "true",
    "data-client-token": paypalLogin,
    locale: "fr_FR",
    currency: "EUR",
    "merchant-id": ["WW4EE7Q9HNUGQ"],
  }).then((paypal) => {
    paypal
      .Buttons({
        style: {
          color: "blue",
          shape: "rect",
        },
        createOrder: function (data, actions) {
          AppStore.setIsLoading(true);
          return actions.order.create({
            purchase_units: [
              {
                amount: {
                  value,
                },
              },
            ],
          });
        },
        //data, actions
        onApprove: (data, actions) => {
          return actions.order.capture().then((details) => {
            const { payer, purchase_units, status, id, create_time } = details,
              variables = {
                invoiceinfo: {
                  email_address: payer.email_address,
                  payer_id: payer.id,
                  create_time,
                  id,
                  status,
                },
                purchaseInput: {
                  value: parseFloat(purchase_units[0].amount.value),
                  currency_code: purchase_units[0].amount.currency_code,
                },
                addressInput: payer.address,
                backerInput: {
                  given_name: payer.name.given_name,
                  surname: payer.name.surname,
                },
                lang: $AppStore.lang,
                choice: becomeMember,
              };

            client
              .mutate({ mutation: addInvoice, variables })
              .then(({ data }) => {
                toast.push(langs[$AppStore.lang || "fr"].thanksMsg);
                AppStore.setContrib(data.addInvoice);
                dispatch("paypalState", {
                  email: payer.email_address,
                  id,
                  given_name: payer.name.given_name,
                  surname: payer.name.surname,
                });
              })
              .catch((e) => {
                toast.push(e.message, toastDanger);
              });
          });
        },
        onError: (err) => {
          toast.push(err, toastDanger);
        },
        onCancel: (data, err) => {
          toast.push(langs[$AppStore.lang || "fr"].cancelPay, toastDanger);
          AppStore.setIsLoading(false);
        },
      })
      .render("#paypal-button-container");
  });
</script>

<div id="paypal-button-container" class="paypal" />

<style>
  .paypal {
    min-width: 500px;
  }

  @media screen and (max-width: 500px) {
    .paypal {
      min-width: 80vw;
    }
  }
</style>
