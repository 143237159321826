<script lang="ts">
  import { AppStore } from "./utils/store";

  function handleClick(lang: "fr" | "en") {
    AppStore.setLang(lang);
  }
</script>

<div class="container">
  <div
    class="lang"
    data-current={$AppStore.lang === "fr"}
    on:click={() => handleClick("fr")}
    title="choice french langage"
  >
    <img src="fr.png" alt="fr flag" />
  </div>
  <div
    class="lang"
    data-current={$AppStore.lang === "en"}
    on:click={() => handleClick("en")}
    title="choice english langage"
  >
    <img src="en.png" alt="fr flag" />
  </div>
</div>

<style>
  .lang img {
    width: 100%;
    height: auto;
    max-width: 25px;
  }

  .container {
    display: flex;
    gap: 10px;
  }

  .lang {
    cursor: pointer;
    transition: 200ms ease-in-out;
    border-bottom: 2px solid transparent;
  }

  .lang[data-current="true"] {
    transform: scale(.7);
    border-bottom: 2px solid var(--color-princ);
  }
  .lang:hover {
    transform: rotate(-5deg);
  }
</style>
