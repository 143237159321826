<script lang="ts">
import { onDestroy, onMount } from "svelte";
import { AppStore } from "./utils/store";
import * as langs from "./utils/lang.json";
import type { InitialState } from "./utils/interfaces";

export let position: "line"|"fixed";
let networks = [], lang="fr"


function generate(){
  networks = [
    {
      name: "Facebook",
      color: "#2d88ff",
      icon: "fab fa-facebook",
      link: `https://www.facebook.com/sharer/sharer.php?u=https://micral.mo5.com&quote=${langs[$AppStore.lang][position === "line" ? "sharer": "nonsharer"]}`,
    },
    {
      name: "Twitter",
      color: "#1da1f2",
      icon: "fab fa-twitter",
      link: `https://twitter.com/intent/tweet?text=${langs[$AppStore.lang][position === "line" ? "sharer": "nonsharer"]}`,
    },
    {
      name: "Linkedin",
      color: "#0a66c2",
      icon: "fab fa-linkedin",
      link: `https://www.linkedin.com/sharing/share-offsite/?url=https://micral.mo5.com&title=${langs[$AppStore.lang]["title"]}&summary=${langs[$AppStore.lang || "fr"][position === "line" ? "sharer": "nonsharer"]}`,
    }
  ];
}
onMount(generate)

const unsubscribe = AppStore.subscribe((store: InitialState) => {

  if(store.lang !== lang){
    generate()
    lang=store.lang
  }
});

onDestroy(unsubscribe);

</script>

<div class="container" data-position={position}>
    <div class="section" data-position={position}>
        {#each networks as {name, color, icon, link}}
        <a class="article" title={name} key={name} href={link} target="_blank">
            <div class="icon" style={`background:${color}`}>
              <i class={icon}/>
            </div>
        </a>
        {/each}
    </div>
</div>

<style>
    .container[data-position="fixed"] {
        position: fixed;
        right: 0;
        z-index: 50;
        top: 50%;
    }

    .container[data-position="line"] {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: center;
        margin:15px;
    }


    .section[data-position="fixed"] {
        display: flex;
        flex-direction: column;
        gap: 5px;
       z-index: 50;
    }


    .section[data-position="line"] {
        display: flex;
        gap: 5px;
    }

    .article {
        color: white;
        font-size: 1.5em;
        transition: 200ms ease-in-out;
        text-align: center;
    }

    .section[data-position="line"] .article {
      font-size: 2em;
    }

    .article:hover {
        transform: translate(-5px, -5px) scale(1.3);
        box-shadow: -3px 3px 0 rgba(0,0,0,.1);
    }

    .fab {
        padding: 5px;
    }
</style>

